#Header {
  display: flex;
  flex-direction: column;
  padding: 20px;
  user-select: none;
  text-align: center;
  color: black;
}



#Langs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;
}

.lang {
  cursor: pointer;
  font-weight: bold;
  margin-right: 20px;
}
.lang:last-child {
  margin-right: 0;
}

.active {
  color: var(--yellow);
}
