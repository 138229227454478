#Intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.intro-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 980px;
}


#Logo {
  margin-top: -140px;
  display: flex;
  align-self: flex-start;
  font-size: 112px;
}

.intro-text-and-pluses {
  display: flex;
  flex-direction: row;
}
.intro-text {
  display: flex;
  flex-direction: column;
  text-align: justify;
  line-height: 1.4;
  margin-right: 10px;
}

.intro-text-elem {
  margin: 4px 0;
}

.intro-enum-elem {
}

.intro-enum {
  font-family: monospace;
  margin-right: 8px;
}

.intro-actual-needs-button {
  cursor: pointer;
  color: var(--yellow);
  font-weight: bold;
}

.intro-pluses {
  display: flex;
  flex-direction: column;
}

.intro-donate-button {
  align-self: flex-end;
  padding-right: 94px;
  z-index: 1;
}

.intro-background-img {
  margin-top: -100px;
  width: 400px;
  height: auto;
}

.intro-ukraine-plus {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  color: black;
  margin-top: -250px;
  font-size: 42px;
  justify-content: center;
}
.intro-ukraine-plus-plus {
  margin-left: 4px;
  font-size: 48px;
}


@media (max-width: 999px) {
  #Intro {
    font-size: 16px;
  }
  .intro-elements {
    width: auto;
    padding: 0 8px 0 8px;
  }
  .intro-text-and-pluses {
    flex-direction: column;
  }
  .intro-text {
    width: auto;
    margin: 0;
  }
  .intro-pluses {
    display: none;
  }
  .intro-donate-button {
    align-self: center;
    padding-right: 0;
  }

  .intro-background-img {
    width: 300px;
    height: auto;
    margin-top: -40px;
  }

  .intro-ukraine-plus {
    margin-top: -190px;
    font-size: 32px;
  }
  .intro-ukraine-plus-plus {
    font-size: 38px;
  }

}
