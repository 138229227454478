#Menu {
  padding: 20px;
}

.menu-wrapper {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  transition: height 0.5s;
}


.menu-toggle {
  width: 80px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  cursor: pointer;
}

.menu-toggle .bar {
  background-color: #777;
  display: block;
  width: 36px;
  height: 4px;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  right: 20px;
  transition: all 0.5s;
}

.menu-toggle .bar:first-child {
  transform: translateY(-10px);
}

.menu-toggle.x .bar {
  transform: rotate(45deg);
}

.menu-toggle.x .bar:first-child {
  transform: rotate(-45deg);
}

.menu-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.menu-item {
  cursor: pointer;
  line-height: 2;
  font-weight: bold;
  color: black;
  font-size: 18px;
}

.menu-item.current {
  color: var(--yellow);
}

@media (max-width: 767px), (max-height: 799px) {

  .menu-toggle {
      display: block;
  }
  .menu-wrapper {
    height: 0;
    margin-top: 40px;
  }
  .menu-wrapper.open {
    height: 200px;
  }

  .menu-elements {
    display: none;
  }
  .menu-elements.open {
    display: flex;
  }

}
