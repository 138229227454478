#Reports {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports-table {
  margin-top: -280px;
}

.reports-img {
  max-width: 200px;
  height: auto;
  cursor: pointer;
}

.reports-img.enlarged {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 90vw;
  border: 10px solid var(--blue);
  height: auto;
  z-index: 1;
}

.reports-donate-button {
}

@media (max-width: 1279px), (max-height: 799px) {
  .reports-table {
    margin-top: 0;
  }

  .reports-img {
    max-width: 100px;
  }
}
