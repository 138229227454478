#Contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
}

.contacts-info {
  display: flex;
  flex-direction: row;
}

.contacts-info-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
}

.contacts-info-part {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 0;
}

.contacts-social-link {
  display: flex;
  align-items: center;
}

.contacts-social-logo {
    width: 28px;
    height: auto;
    margin: 0 4px;
}

.contacts-donate-button {
  align-self: flex-end;
  margin-right: 140px;
}

@media (max-width: 767px) {
  .contacts-info {
    flex-direction: column;
  }

  .contacts-info-text {
    margin: 0;
    align-items: center;
  }

  .contacts-info-part {
    align-items: center;
  }

  .contacts-donate-button {
    margin: 0;
    align-self: center;
  }
}
