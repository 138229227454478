#Team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-table {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--blue);
  margin-top: -280px;
}


.team-table-row {
  display: flex;
  flex-direction: row;
}

.team-table-cell-short {
  display: flex;
  width: 200px;
  height: 200px;
  border: 1px solid var(--blue);
}

.team-table-cell-long {
  display: flex;
  justify-content: center;
  width: 400px;
  height: 200px;
  border: 1px solid var(--blue);
}

.team-img {
  max-width: 200px;
  height: auto;
  cursor: pointer;
}

.team-img-decor {
  max-width: 200px;
  height: auto;
}

.team-img.enlarged {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 90vw;
  border: 10px solid var(--blue);
  height: auto;
  z-index: 1;
}

.team-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-member-row {
  display: flex;
  line-height: 1.5;
}

.team-member-name {
  font-weight: bold;
}

.team-u-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 120px;
  font-weight: 900;
  background-color: var(--yellow);
  color: var(--yellow);
  -webkit-text-stroke: 2px var(--blue);
  -webkit-text-fill-color: var(--yellow);
}

.team-volunteers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.team-volunteers-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-volunteers-text-1 {
  font-size: 28px;
  font-weight: bold;
}

.team-volunteers-text-2 {
  font-weight: bold;
}

.team-donate-button {

}

@media (max-width: 767px), (max-height: 799px) {
  .team-table {
    margin-top: 0;
  }

  .team-img {
    max-width: 100px;
    height: auto;
  }


  .team-table-cell-short {
    width: 100px;
    height: 100px;
  }

  .team-table-cell-long {
    width: 200px;
    height: 100px;
    font-size: 12px;
  }

  .team-u-plus {
    font-size: 60px;
  }

  .team-volunteers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  .team-volunteers-text {
  }

  .team-volunteers-text-1 {
    font-size: 16px;
  }

  .team-volunteers-text-2 {
    font-size: 12px;
  }

  .team-img-decor {
    width: 100px;
    height: 100px;
  }

}

@media (max-width: 1279px), (max-height: 799px) {
  .team-table {
    margin-top: 0;
  }
}
