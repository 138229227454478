
.donate-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  color: var(--yellow);
  cursor: pointer;
}
.donate-button-plus {
  margin-left: 16px;
  margin-top: 6px;
  font-size: 48px;
  font-weight: 900;
}

.u-plus {
  font-weight: 900;
  color: var(--blue);
}

.u-plus.hollow {
  -webkit-text-stroke: 2px var(--yellow);
  -webkit-text-fill-color: var(--grey);
}

.pluses {
  display: flex;
  flex-direction: column;
}
.pluses-row {
  font-weight: 900;
  font-size: 48px;
  line-height: 1;
  color: black;
}

.pluses-shifted {
  display: flex;
  flex-direction: column;
}
.pluses-shifted-row {
  font-weight: 900;
  line-height: 0.5;
}



.table {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--blue);
}

.table-row {
  display: flex;
  flex-direction: row;
}

.table-cell {
  display: flex;
  width: 200px;
  height: 200px;
  border: 1px solid var(--blue);
}


@media (max-width: 1279px), (max-height: 799px) {
  .table-cell {
    width: 100px;
    height: 100px;
  }
}
