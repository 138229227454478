#Needed {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
#table-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--yellow);
  margin-top: -280px;
}


.table-item {
  display: flex;
  flex-direction: row;
  width: 604px;
  height: 200px;
}

.table-item-img {
  width: 198px;
}

.table-item-info {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 200px;
}

.table-item-info-name {
  height: 49px;
}
.table-item-info-team {
  height: 49px;
}
.table-item-info-cost {
  display: flex;
  flex-direction: row;
  height: 99px;
}

.table-item-info-cost-info {
  flex-direction: column;
  width: 132px;
}

.table-item-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--yellow);
  padding: 2px;
}

.table-footer {
  display: flex;
  flex-direction: row;
  width: 604px;
  height: 100px;
}

.table-footer-needed {
  flex-direction: column;
  width: 202px;
}

.table-footer-available {
  flex-direction: column;
  width: 132px;
}

.table-footer-missing {
  flex-direction: column;
  width: 266px;
}

.table-footer-amount {
  font-size: 18px;
  font-weight: 900;
}

.table-footer-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--yellow);
  background-color: var(--blue);
  color: var(--yellow);
}

.needed-donate-button {
  padding: 8px 0 8px 0;
}


@media (max-width: 767px) {
  #Needed {
    font-size: 12px;
  }

  .table-item {
    width: 364px;
  }

  .table-item-img {
    width: 98px;
    height: auto;
  }

  .table-item-info {
    width: 260px;
  }

  .table-footer {
    width: 364px;
  }

  .table-footer-needed {
    width: 104px;
  }

  .table-footer-available {
    width: 88px;
  }

  .table-footer-missing {
    width: 174px;
  }

}

@media (max-width: 1279px), (max-height: 799px) {
  #table-wrapper {
    margin-top: 0;
  }
}
