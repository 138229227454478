#Donate {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: black;
  font-size: 18px;
}

.donate-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -280px;
}

.bank-items {
  display: flex;
  flex-direction: column;
}

.bank-item-title {
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 20px;
}

.bank-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 0 64px 0;
}

.bank-item-element {
  margin: 0 0 12px 0;
}

.bank-item-copy {
  margin-left: 8px;
  cursor: pointer;
}

.donate-images {
  display: flex;
  flex-direction: column;
}

.donate-image {
  width: 200px;
  height: auto;
  margin-top: -75px;
  margin-bottom: -65px;
}


@media (max-width: 767px) {
  .donate-wrapper {
    flex-direction: column;
    margin-top: 0;
  }
  .bank-item {
    align-items: center;
  }
  .donate-images {
    align-items: center;
  }
  .donate-image {
    display: none;
  }
  .donate-image:first-of-type {
    display: flex;
  }
}

@media (max-width: 767px), (max-height: 799px) {
  .donate-wrapper {
    margin-top: 0;
  }
}

@media (max-width: 1279px), (max-height: 799px) {
  .donate-wrapper {
    margin-top: 0;
  }
}
